@font-face {
  font-family: sans;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/sans/eot/IRANSansWeb_Bold.eot");
  src: url("../fonts/sans/eot/IRANSansWeb_Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/sans/woff2/IRANSansWeb_Bold.woff2") format("woff2"), url("../fonts/sans/woff/IRANSansWeb_Bold.woff") format("woff"), url("../fonts/sans/ttf/IRANSansWeb_Bold.ttf") format("truetype"); }

@font-face {
  font-family: sans;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/sans/eot/IRANSansWeb_Medium.eot");
  src: url("../fonts/sans/eot/IRANSansWeb_Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/sans/woff2/IRANSansWeb_Medium.woff2") format("woff2"), url("../fonts/sans/woff/IRANSansWeb_Medium.woff") format("woff"), url("../fonts/sans/ttf/IRANSansWeb_Medium.ttf") format("truetype"); }

@font-face {
  font-family: sans;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/sans/eot/IRANSansWeb_Light.eot");
  src: url("../fonts/sans/eot/IRANSansWeb_Light.eot?#iefix") format("embedded-opentype"), url("../fonts/sans/woff2/IRANSansWeb_Light.woff2") format("woff2"), url("../fonts/sans/woff/IRANSansWeb_Light.woff") format("woff"), url("../fonts/sans/ttf/IRANSansWeb_Light.ttf") format("truetype"); }

@font-face {
  font-family: sans;
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/sans/eot/IRANSansWeb_UltraLight.eot");
  src: url("../fonts/sans/eot/IRANSansWeb_UltraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/sans/woff2/IRANSansWeb_UltraLight.woff2") format("woff2"), url("../fonts/sans/woff/IRANSansWeb_UltraLight.woff") format("woff"), url("../fonts/sans/ttf/IRANSansWeb_UltraLight.ttf") format("truetype"); }

@font-face {
  font-family: sans;
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/sans/eot/IRANSansWeb.eot");
  src: url("./fonts/sans/eot/IRANSansWeb.eot?#iefix") format("embedded-opentype"), url("./fonts/sans/woff2/IRANSansWeb.woff2") format("woff2"), url("./fonts/sans/woff/IRANSansWeb.woff") format("woff"), url("./fonts/sans/ttf/IRANSansWeb.ttf") format("truetype"); }

@font-face {
  font-family: sans_number;
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/IRANSansWeb.eot");
  src: url("./fonts/IRANSansWeb.eot?#iefix") format("embedded-opentype"), url("./fonts/IRANSansWeb.woff2") format("woff2"), url("./fonts/IRANSansWeb.woff") format("woff"), url("./fonts/IRANSansWeb.ttf") format("truetype"); }
@font-face {
	font-family: 'personal';
	src:  url('../fonts/axanto/personal.eot?ewk8zz');
	src:  url('../fonts/axanto/personal.eot?ewk8zz#iefix') format('embedded-opentype'),
	  url('../fonts/axanto/personal.ttf?ewk8zz') format('truetype'),
	  url('../fonts/axanto/personal.woff?ewk8zz') format('woff'),
	  url('../fonts/axanto/personal.svg?ewk8zz#personal') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }
  
  [class^="pr-"], [class*=" pr-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'personal' !important;
	speak: never;
	font-style: normal; 
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  .pr-icons8-cancel:before {
    content: "\e901";
  }
  .pr-icons8-menu-48:before {
    content: "\e90f";
  }
  .pr-plus-svgrepo-com-1:before {
    content: "\e907";
  }
  .pr-alert-triangle-svgrepo-com:before {
    content: "\e906";
  }
  .pr-approved-aproved-confirm-2-svgrepo-com:before {
    content: "\e900";
  }
  .pr-arow-down:before {
    content: "\e902";
  }
  .pr-arrow-left:before {
    content: "\e903";
  }
  .pr-arrow-right:before {
    content: "\e904";
  }
  .pr-faq:before {
    content: "\e905";
  }
  .pr-image:before {
    content: "\e909";
  }
  .pr-recyclebin:before {
    content: "\e908";
  }
  .pr-star:before {
    content: "\e90c";
  }
  .pr-star-o:before {
    content: "\e90d";
  }
  
  
  