.footer
{
    &__item
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 0;
        margin-top: 30px;
        &__logo
        {
            img
            {
                width: 120px;
                height: 23.28px;
            }
        }
        &__social
        {
            ul
            {
                display: flex;
                list-style: none;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 0;
                margin: 0;
                li
                {
                    margin-right: 24px;
                    &:last-child
                    {
                        margin-right: 0;
                    }
                    a
                    {
                        img
                        {
                            width: 36px;
                            height: 36px;
                        }
                    }
                }
            }
        }
    }
    &__copyright
    {
        background-color: #c1c1c1;
        display: flex;
        align-items: flex-start;
        display: flex;
        padding: 10px 0;
        ul
        {
            display: flex;
            align-items: center;
            list-style: none;
            padding: 0;
            margin: 0;
            margin-right: auto;
            li
            {
                border-right: 1px solid;
                padding-left: 5px;
                padding-right: 5px;
                &:last-child
                {
                    border-right: none;
                }
                span
                {
                    color: #0d1641;
                    font-size: 18px;
                    letter-spacing: 0.23px;
                    font-weight: 300;
                }
                a
                {
                    font-size: 18px;
                    letter-spacing: 0.23px;
                    text-decoration: none;
                    color: #a40083;
                    font-weight: 300;
                    &:hover
                    {
                        color: #005EB8;
                    }
                }
            }
        }
        img
        {
            margin-right: auto;
            width:134px ;
            height:35px ;
            padding: 10px 0;
            object-fit: cover;
            margin-inline-start: 2em;
        }
    }
}
@media (max-width:768px)
{
    .footer
    {
        &__item
        {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &__social
            {
                margin-top: 30px;
                ul
                {
                    flex-wrap: wrap;
                    li
                    {
                        margin-bottom: 20px;
                    }
                }
            }
        }
        &__copyright
        {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            ul
            {
                margin: 0 auto;
            }
            img
            {
                margin: 10px auto 0;
            }
        }
    }
}