.contact
{
    background-color: #f2f2f2;
    padding-bottom: 50px;
    margin-top: 100px;
    &__title
    {
        text-align: center;
        align-items: center;
        padding: 50px 0;
        margin-bottom: 15px;
        h2
        {
            font-size: 38px;
            letter-spacing: 0.48px;
            text-align: center;
            font-weight: 500;
            margin-bottom: 0;
        }
        p
        {
            font-size: 38px;
            letter-spacing: 0.48px;
            text-align: center;
            color: #005eb8;
            margin: 0;
            margin-top: 15px;
        }

    }
    &__check
    {
        background-color: #005eb8;
        padding: 10px;
        margin-bottom: 20px;
        label
        {
            font-size: 13px;
            font-weight: 500;
            color: #fff;
            margin-left: 5px;
        }
    }
    form
    {
        .form-group
        {
            >input[type=text]
            {
                width: 100%;
                height: 48px;
                margin-bottom: 20px;
                text-indent: 20px;
                border-radius: 4px;
                border: solid 1px #979797;
    
            }
            >input[type=email]
            {
                width: 100%;
                height: 48px;
                margin-bottom: 20px;
                text-indent: 20px;
                border-radius: 4px;
                border: solid 1px #979797;
    
            }
            label
            {
                color: #a20067;
                font-weight: 500;
                padding: 30px 0;
                
            }

        }
        p
        {
            font-size: 16px;
            font-weight: 300;
            line-height: 20px;
            color: #747b80;
            margin-bottom: 20px;
        }
        button
        {
            background-color: #005eb8;
            border-radius: 26px;
            color: #fff;
            align-items: center;
            width: 245px;
            height: 52px;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.23px;
            text-align: center;
            border: none;
            transition: 0.2s;
            &:hover
            {
                background-color: #a20067;
            }
        }

    }
    img
    {
        width: 100%;
        height: auto;
        vertical-align: bottom;
    }
    &__info
    {
        margin: 20px 0;
        p
        {
            font-size: 18px;
            letter-spacing: 0.23px;
            margin: 0;
            &.name
            {
                font-size: 28px;
                letter-spacing: 0.35px;
                
            }
            a
            {
                color: #a20067;
                transition: 0.2s;
                &:hover
                {
                    color: #005eb8;
                }

            }
        }
        span
        {
            font-size: 18px;
            letter-spacing: 0.23px;
            margin-bottom: 20px;
            display: block;
        }   
    }
}
@media(max-width:992px)
{
    .contact
    {
        form
        {
            margin-bottom: 30px;
        }
    }
}
@media (max-width:576px)
{
    .contact
    {
        &__title
        {
            h2
            {
                font-size: 22px;
            }
            p
            {
                font-size: 18px;
            }
        }
        &__info
        {
            p
            {
                &.name
                {
                    font-size: 20px;
                }
            }
        }
    }
}