.solutions
{
    margin-top: 100px;
    margin-bottom: 100px;
    &__title
    {
        text-align: center;
        align-items: center;
        padding: 50px 0;
        h2
        {
            font-size: 38px;
            letter-spacing: 0.48px;
            text-align: center;
            font-weight: 500;
            margin-bottom: 0;
        }
        p
        {
            font-size: 38px;
            letter-spacing: 0.48px;
            text-align: center;
            color: #005eb8;
            margin: 0;
            margin-top: 15px;
            font-weight: 400;
        }

    }
    &__item
    {
        margin: 50px 0;
        &__image
        {
            img
            {
                width: 100%;
                height: auto;
            }
        }
        &__title
        {
            text-align: center;
            h3
            {
                font-size: 38px;
                letter-spacing: 0.48px;
                text-align: center;
                color: #0d1641;
                font-weight: 500;
            }
            span
            {
                font-size: 18px;
                letter-spacing: 0.23px;
                text-align: center;
                color: #0d1641;
                margin: 15px 0;
                display: block;
                font-weight: 500;
            }
        }
        &__content
        {
            p
            {
                color: #747b80;
            }
        }
    }
    &__swiper
    {
        background-color: #f2f2f2;
        text-align: center;
        padding: 20px;
        img
        {
            width: 100%;
            height: auto;
        }
        h4
        {
            text-align: center;
            color: #0d1641;
            font-size: 22px;
            line-height: 1.5;
            font-weight: 500;
        }
        p
        {
            color: #747b80;
            font-size: 16px;
            line-height: 1.5;
            font-weight: normal;
            text-align: center;
            font-weight: 400;
        }
        .swiper-pagination
        {
            bottom: -5px!important;
            .swiper-pagination-bullet-active
            {
                background: #a20067!important;
            }
        }
        .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after 
        {
            content: "next";
            color: #000;
            font-size: 28px;
        }
        .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
            content: "prev";
            color: #000;
            font-size: 28px;
        }
    }    
    &__bottom
    {
        margin-top: 20px;
        background-color: transparent;
        background-image: linear-gradient(70deg, #0169B4 0%, #FF005B 62%);
        padding: 20px 0;
        h5
        {
            text-align: center;
            color: #fff;
            font-size: 38px;
            letter-spacing: 0.48px;
            text-align: center;
        }
        &__item
        {
            margin-top: 20px;
            &__list
            {
                text-align: center;
                img
                {
                    width: 98px;
                    height: 96px;
                    margin-bottom: 20px;
                }
                p
                {
                    color: #fff;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                }

            }
        }
    }
}
@media (max-width:576px)
{
    .solutions
    {
        &__title
        {
            h2
            {
                font-size: 22px;
            }
            p
            {
                font-size: 18px;
            }
        }
        &__item
        {
            &__title
            {
                h3
                {
                    font-size: 20px;
                }
                span
                {
                    font-size: 16px;
                }
            }
        }
        &__bottom
        {
            h5
            {
                font-size: 22px;
            }
            
        }
    }
}

