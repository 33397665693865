.reference
{
    background-color: #f2f2f2;
    margin-bottom: 100px;
    padding-bottom: 50px;
    &__title
    {
        text-align: center;
        align-items: center;
        padding: 50px 0;
        margin-bottom: 15px;
        h2
        {
            font-size: 38px;
            letter-spacing: 0.48px;
            text-align: center;
            font-weight: bold;
            margin-bottom: 0;
        }
        p
        {
            font-size: 38px;
            letter-spacing: 0.48px;
            text-align: center;
            color: #005eb8;
            margin: 0;
            margin-top: 15px;
            font-weight: bold;
        }

    }
    &__item
    {
        background-color: #fff;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding: 18px;
        &__image
        {
            flex: 0 0 30%;
            max-width: 310px;
            img
            {
                width: 100%;
                height: auto;
            }
        }
        &__content
        {
            margin-left: 40px;
            h3
            {
                font-family: VinciSans;
                font-size: 28px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.35px;
            }
            p
            {
                font-size: 28px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.35px;
                color: #a20067;
            }
        }
    }

}
@media (max-width:576px)
{
    .reference
    {
        &__title
        {
            h2
            {
                font-size: 22px;
            }
            p
            {
                font-size: 18px;
            }
        }
        &__item
        {
            &__content
            {
                h3
                {
                    font-size: 18px;
                }
                p
                {
                    font-size: 16px;
                }
            }
        }
    }
}