.header
{
    position: sticky;
    top: 0;
    background-color: #fff;
    
    z-index: 999999;
    &__item
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 35px 0;
        &__logo
        {
            img
            {
                width: 173px;
                height: 34px;
                object-fit: contain;
            }
        }
        &__menu
        {

            nav
            {
                ul
                {
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    list-style: none;
                    justify-content: space-between;
                    li
                    {
                        margin-right: 30px;
                        &:last-child
                        {
                            margin-right: 0;
                        }
                        
                        a
                        {
                            color: #000;
                            text-decoration: none;
                            transition: 0.2s;
                            font-size: 16px;
                            font-weight: 500;
                            &:hover
                            {
                                text-decoration: underline;
                                color: #a20067;
                            }
                        }
                    }
                }
            }
        }
        &__image
        {
            img
            {
                width: 134px;
                height: 21.08px;
                object-fit: contain;
            }
        }
    }
    &__mobile
    {
        display: none;
        &__item
        {
            display: flex;
            align-items: center;
            justify-content: space-between; 
            padding: 35px 0;
            &__logo
            {
                img
                {
                    width: 173px;
                    height: 34px;
                    object-fit: contain;
                }
            }
            &__icon
            {
                i
                {
                    font-size: 28px;
                    color: #a20067;
                    cursor: pointer;
                }
            }
            &__menu
            {
                position: absolute;
                left: 0;
                top: 100% ;
                width: 100%;
                box-shadow: 0 0 5px rgba(0, 0, 0 ,10% );
                background-color: #fff;
                padding: 20px;
                display: none;
                ul
                {
                    padding: 0;
                    list-style: none;
                    max-width: 540px;
                    margin: 0 auto;
                    li
                    {
                        margin-bottom: 20px;
                        a
                        {
                            color: #000;
                            text-decoration: none;
                            transition: 0.2s;
                            font-size: 16px;
                            font-weight: 500;
                            &:hover
                            {
                                text-decoration: underline;
                                color: #a20067;
                            }
                        }
                    }
                }
                
            }
            &__image
            {
                max-width: 540px;
                margin: 0 auto;
                img
                {
                    width: 134px;
                    height: 21.08px;
                    object-fit: contain;
                }
            }
        }
    }
    .progress-bar
    {
        background-color: transparent;
        background-image: linear-gradient(70deg, #0169B4 0%, #FF005B 62%);
        height: 4px;
        width: 0;
    }
}
@media (max-width:992px)
{
    .header
    {

        &__item
        {
            
            &__logo
            {

            }
            &__menu
            {
                nav
                {
                    ul
                    {
                        flex-wrap: wrap;
                        li
                        {
                            margin-right: 20px;
                        }
                    }
                }
            }
            &__image
            {

            }
        }
    }
}
@media (max-width:768px)
{

    .header
    {
        &__item
        {
            display: none;
        }
        &__mobile
        {
            display: block;
        }
    }
}