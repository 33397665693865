.slider
{
    position: relative;
    &__background
    {
        position: relative;
        min-height: 900px;
        span
        {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            opacity: .5;
            background: linear-gradient(135deg,rgb(0,94,184) 2%,rgb(162,0,103) 88%);
            z-index: 0;
        }
        video
        {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            max-width: none;
            max-height: none;
            object-fit: cover;
            outline: none;
            border: none;
            box-shadow: none;
            z-index: -1;
        }
    }
    &__item
    {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%,-50%);
        text-align: center;
        max-width: 1320px;
        &__image
        {
            margin-bottom: 20px;
            img
            {
                width: 243px;
                height: 47.78px;
                object-fit: contain;
            }
        }
        &__title
        {
            h1
            {
                font-size: 60px;
                letter-spacing: 0.75px;
                text-align: center;
                color: #fff;
                font-weight: 500;
            }
        }
        &__content
        {
            p
            {
                font-size: 38px;
                letter-spacing: 0.48px;
                text-align: center;
                color: #fff;
            }
        }
        &__button
        {
            margin-top: 20px;
            button
            {
                background-color: transparent;
                border-radius: 26px;
                color: #fff;
                align-items: center;
                width: 245px;
                height: 52px;
                text-align: center;
                text-decoration: none;
                align-self: center;
                border: 2px solid #fff;
                transition: 0.2s;
                &:hover
                {
                    border-color: #a20067;
                    background-color: #a20067;
                }
            }

        }

    }
}
@media (max-width:576px)
{
    .slider
    {
        &__background
        {
            min-height: 600px;
        }
        &__item
        {
            &__image
            {

            }
            &__title
            {
                h1
                {
                    font-size: 22px;
                }
            }
            &__content
            {
                p
                {
                    font-size: 18px;
                }
            }
            &__button
            {
                button
                {

                }
            }
        }
    }
}