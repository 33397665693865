.news
{
    &__title
    {
        text-align: center;
        align-items: center;
        padding: 50px 0;
        margin-bottom: 15px;
        h2
        {
            font-size: 38px;
            letter-spacing: 0.48px;
            text-align: center;
            font-weight: bold;
            margin-bottom: 0;
        }
        p
        {
            font-size: 38px;
            letter-spacing: 0.48px;
            text-align: center;
            color: #005eb8;
            margin: 0;
            margin-top: 15px;
            font-weight: bold;
        }

    }
    &__item
    {
        max-width: 831.5px;
        margin: 0 auto;
        &__title
        {

            a
            {
                text-decoration-thickness: 1px;
                text-underline-offset: 0.25ch;
                color: #000;
                transition: 0.2s;
                &:hover
                {
                    text-decoration-style: dashed;
                    color: #005eb8;
                }
            }
            span
            {
                display: block;
                font-size: .8125em;
            }
        }
        &__content
        {
            p
            {
                margin-top: 0.5em;
                margin-bottom: 1em;
                font-weight: 300;
                line-height: normal;
            }
        }
    }
}